<template>
  <div class="mainPage">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{$t('account.account')}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{$t('account.info')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <h2>{{ backupInfo.companyName }}</h2>
    <PlanList :planList="backupInfo.restInPlan" />
    <br /><br />
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-user"></i>&nbsp;{{$t('account.info')}}</span>
      </div>
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item :label="$t('account.contactor')" prop="contactor">
          <el-input v-model="form.contactor" maxlength="50" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('account.mobile')" prop="mobile">
          <el-input v-model="form.mobile" maxlength="11" style="width: 70%;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('account.mail1')" prop="mail1">
          <el-input v-model="form.mail1" maxlength="50" style="width: 70%;"></el-input>
          <el-tag type="success" v-if="form.systemmail == 1">{{$t('account.systemmail')}}</el-tag>&nbsp;
          <el-tag type="success" v-if="form.billmail == 1">{{$t('account.billmail')}}</el-tag>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">{{$t('account.update')}}</el-button>
          <el-button @click="resetForm()">{{$t('account.cancel')}}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { detail, update } from "@/api/account";
import PlanList from "@/components/account/accountInfo/PlanList.vue";
import { mapState } from "vuex";
export default {
  name: "AccountInfo",
  components: { PlanList },
  data() {
    
    return {
      backupInfo: { companyName: "", restInPlan: [] },
      form: {
        contactor: "",
        mobile: "",
        mailFlg1: false,
        mailFlg2: false
      },
      rules: {
        contactor: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { min: 1, max: 20, message: "长度在 1 到 20 个字符", trigger: "blur" }
        ],
        mobile: [
          { required: true, message: "请输入联系人手机", trigger: "blur" },
          { min: 11, max: 11, message: "手机长度需是11位", trigger: "blur" }
        ],
        mail1: [
          { required: true, message: "请输入联系邮件", trigger: "blur" },
          { min: 6, max: 50, message: "请输入正确的邮件地址", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    refreshData() {
      detail({
        userID: this.accountInfo.userID
      }).then(response => {
        this.backupInfo = response.data;
        this.form = { ...response.data };
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          update({
            userID: this.accountInfo.userID,
            ...this.form
          }).then(response => {
            this.$message({
              message: response.message,
              type: "success"
            });
            this.refreshData();
          });
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.form = { ...this.backupInfo };
    }
  }
};
</script>
