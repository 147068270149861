<template>
  <el-card class="box-card" shadow="never">
    <div slot="header" class="clearfix">
      <span><i class="el-icon-bank-card"></i>&nbsp;套餐信息</span>
    </div>
    <el-table :data="planList" style="width: 100%">
      <el-table-column prop="function" label="功能"> 
        <template slot-scope="scope">
          {{scope.row.function}}
        </template>
      </el-table-column>
      <el-table-column prop="plan" label="套餐名称"> 
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">{{scope.row.comment}}</div>
            <span class="dash-num-orange">{{scope.row.plan}}&nbsp;<i class="el-icon-info"></i></span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="start" label="有效期开始"> </el-table-column>
      <el-table-column prop="end" label="有效期结束"> </el-table-column>
      <el-table-column prop="amount" label="余量（次）">
        <template slot-scope="scope">
            <div class="dash-num-blue">{{scope.row.amount}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="balance" label="余额（元）">
        <template slot-scope="scope">
          <div class="dash-num-blue">{{scope.row.balance}}</div>
        </template>
      </el-table-column>
    </el-table>   
  </el-card>
</template>

<script>
export default {
  name: "PlanList",
  props: {
    planList: Array
  }
};
</script>
<style></style>
